<template>
    <a-banner class="report-banner">
        View where your content was published and discover your search rankings
        with the Mentions Report below.
        <br />
        <br />
        <strong>Please be aware</strong>
        that mentions may appear within 30 days of publication as your content
        spreads.
        <br />
        Keep checking periodically for updates.
    </a-banner>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import ABanner from '@/components/ABanner';

@Component({ components: { ABanner } })
export default class ReportTopBanner extends Vue {}
</script>

<style lang="scss" scoped>
.report-banner {
    background-position: bottom right;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .report-banner {
        padding-bottom: 100px;
        background-size: 45%;
        background-image:
            url('@/assets/img/report-banner/top-art.svg'),
            url('@/assets/img/report-banner/bg-tall.svg');
    }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
    .report-banner {
        padding-bottom: 100px;
        background-size: 30%;
        background-image:
            url('@/assets/img/report-banner/top-art.svg'),
            url('@/assets/img/report-banner/bg-tall.svg');
    }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
    .report-banner {
        padding-bottom: 12px;
        background-size: auto;
        background-position: right;
        background-image:
            url('@/assets/img/report-banner/top-art.svg'),
            url('@/assets/img/report-banner/bg.svg');
    }
}
</style>
