<template>
    <div class="report-container">
        <v-chip
            v-if="isMsn"
            label
            class="report-status-chip px-6"
            color="msn-blue"
            text-color="white"
        >
            MSN
        </v-chip>
        <v-chip
            v-else-if="isFinancial"
            label
            class="report-status-chip px-6"
            color="primary"
            text-color="white"
        >
            Financial
        </v-chip>
        <v-chip
            v-else-if="isPremium"
            label
            class="report-status-chip px-6"
            color="primary"
            text-color="white"
        >
            Premium
        </v-chip>
        <v-chip
            v-else-if="isCrypto"
            label
            class="report-status-chip px-6"
            color="turquoise"
            text-color="white"
        >
            Crypto Premium
        </v-chip>
        <v-chip
            v-else-if="isLimited"
            label
            class="report-status-chip px-6"
            color="dark-orange"
            text-color="white"
        >
            Selective
        </v-chip>
        <v-container
            class="blue-header pt-8"
            :class="{
                'pa-4': $vuetify.breakpoint.smAndDown,
                'pa-8': $vuetify.breakpoint.mdAndUp
            }"
        >
            <v-row>
                <v-col cols="12" class="text-center headline pa-0">
                    <div
                        v-if="loading"
                        class="d-flex flex-column align-center pt-3"
                    >
                        <v-skeleton-loader loading type="text" width="90%" />
                        <v-skeleton-loader loading type="text" width="60%" />
                        <v-skeleton-loader
                            loading
                            type="text"
                            width="50%"
                            height="16"
                        />
                    </div>
                    <span v-else>&laquo;{{ headline }}&raquo; Mentions</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="12"
                    md="4"
                    class="text-body-2 font-weight-bold text-center"
                >
                    <v-skeleton-loader
                        v-if="loading"
                        loading
                        type="text"
                        class="pt-1 d-flex justify-end float-end"
                        width="80%"
                    />
                    <div v-else>
                        {{ publishedAt }}
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    class="text-center text-body-2"
                    :class="{ 'py-0': $vuetify.breakpoint.smAndDown }"
                >
                    <label>Brand Mentions</label>
                    <v-skeleton-loader
                        v-if="loading"
                        loading
                        type="button"
                        class="mb-n1 d-flex justify-center"
                    />
                    <div
                        v-else
                        class="pa-0 text-h5 font-weight-bold d-flex justify-center"
                    >
                        {{ mentionsCount }}
                        <!-- We need clickable tooltip here, and native v-tooltip cannot prodive us with that. So we emulate it using v-menu -->
                        <v-menu
                            offset-y
                            close-delay="100"
                            transition="scale-transition"
                            :open-on-hover="!$vuetify.breakpoint.mobile"
                            :left="$vuetify.breakpoint.smAndDown"
                            :bottom="$vuetify.breakpoint.smAndDown"
                            :max-width="
                                $vuetify.breakpoint.smAndDown ? 'auto' : '20vw'
                            "
                            :origin="
                                $vuetify.breakpoint.mdAndUp
                                    ? 'bottom left'
                                    : 'top right'
                            "
                            :offset-x="$vuetify.breakpoint.mdAndUp"
                            :right="$vuetify.breakpoint.mdAndUp"
                            :top="$vuetify.breakpoint.mdAndUp"
                        >
                            <template #activator="{ on, attrs }">
                                <v-hover v-slot="{ hover }">
                                    <v-icon
                                        small
                                        :color="
                                            hover ? 'secondary' : 'tertiary'
                                        "
                                        class="ml-1"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        circle-info
                                    </v-icon>
                                </v-hover>
                            </template>
                            <div class="secondary--text body-2 px-6 py-4">
                                Mentions define a list of references to your
                                business/company that were posted after the
                                publishing of Amp.
                                <span v-if="googlePickupUrl">
                                    <a
                                        :href="googlePickupUrl"
                                        target="_blank"
                                        class="d-inline-block"
                                    >
                                        Click here
                                    </a>
                                    to check out the pick-up in Google to see
                                    how many pages Google has discovered and
                                    indexed, which represents just a partial
                                    list of where your release and news have
                                    been published, shared, and discussed.
                                </span>
                            </div>
                        </v-menu>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    class="text-body-2 font-weight-bold text-center"
                >
                    <v-skeleton-loader
                        v-if="loading"
                        loading
                        type="text"
                        class="pt-1 d-flex justify-end float-end"
                        width="80%"
                    />
                    <div v-else class="break-words">
                        {{ companyName }}
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="content">
            <brand-mentions
                id="mentions"
                v-intersect="intersecter"
                :loading="loading"
                :mentions="mentions"
                :google-pickup-url="googlePickupUrl"
                :distribution-type="distributionType"
            />
        </v-container>
        <v-container class="content mt-4">
            <brand-keywords
                id="keywords"
                v-intersect="intersecter"
                :loading="loading"
                :keywords="keywords"
            />
        </v-container>
        <v-container class="mt-4">
            <download-report
                id="download"
                ref="downloader"
                v-intersect="intersecter"
                :company="company"
            />
        </v-container>
        <v-container class="mt-4">
            <report-faq
                id="faq"
                v-intersect="intersecter"
                :questions="questions"
            />
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { formatDate } from '@/utils/helpers';

import BrandMentions from './BrandMentions';
import BrandKeywords from './BrandKeywords';
import DownloadReport from './DownloadReport';
import ReportFaq from './ReportFaq';

import type { Report } from '@/types/Report';

type SectionIntersection = {
    intersecting: boolean;
    ratio: number;
    element: HTMLElement | null;
};

type Faq = { question: string; answer: string };

const DistributionReportProps = Vue.extend({
    name: 'DistributionReport',
    props: {
        report: {
            type: Object as PropType<Report | null>,
            default() {
                return {
                    press_release: null,
                    mentions: [],
                    keywords: {},
                    modules: []
                };
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        BrandMentions,
        BrandKeywords,
        DownloadReport,
        ReportFaq
    }
})
export default class DistributionReport extends DistributionReportProps {
    $refs!: {
        downloader: DownloadReport;
    };

    get headline() {
        return this.report?.press_release?.headline || '';
    }

    get company() {
        return this.report?.press_release?.announcement?.company;
    }

    get publishedAt() {
        return this.report?.press_release?.announcement?.min_distribution_time
            ? formatDate(
                  this.report.press_release.announcement.min_distribution_time
              )
            : '';
    }

    get companyName() {
        return this.company?.name || '';
    }

    get mentionsCount() {
        return this.report?.mentions.length;
    }

    get mentions() {
        return this.report?.mentions || [];
    }

    get distributionType() {
        return {
            is_crypto: this.report?.press_release?.is_crypto_premium,
            is_premium: this.report?.press_release?.is_premium,
            is_financial: this.report?.press_release?.is_financial
        };
    }

    get keywords() {
        return this.report?.keywords || {};
    }

    get googlePickupUrl() {
        if (this.report?.press_release?.google_pickup_url) {
            // include all module names into Google pickup URL
            if (this.report.headlines.length) {
                const [host, query] = (
                    this.report?.press_release?.google_pickup_url || ''
                ).split('q=');

                const [, options] = query.split('&gl=');

                const parts = [host, 'q=', '', '&gl=', options];

                parts[2] = this.report.headlines
                    .reduce((acc: string[], name: string) => {
                        acc.push(`"${name}"`);

                        return acc;
                    }, [])
                    .join(' OR ');

                return parts.join('').replace(/\s+/g, '+');
            }

            return this.report?.press_release?.google_pickup_url;
        }

        return '';
    }

    get isPremium() {
        return this.report?.press_release?.is_premium || false;
    }

    get isFinancial() {
        return this.report?.press_release?.is_financial || false;
    }

    get isMsn() {
        return this.report?.press_release?.is_msn || false;
    }

    get isLimited() {
        return this.report?.press_release?.limited_distribution_only || false;
    }

    get isCrypto() {
        return this.report?.press_release?.is_crypto_premium || false;
    }

    get intersecter() {
        return {
            handler: this.onIntersect,
            options: {
                threshold: [0, 0.25, 0.5, 0.75, 1]
            }
        };
    }

    get supportLink() {
        return '<a href="mailto:support@ampifire.com" target="_blank" rel="nofollow noopener">support@ampifire.com</a>';
    }

    questions: Faq[] = [
        {
            question: `Are these the only keywords or keyword combinations I rank for?`,
            answer: `The keyword cloud is not a comprehensive list of all the keywords that your amp or your company page will rank for. This list is derived from combinations of words in your news headline but the combinations may not be the actual keywords you would like to rank for. To gauge the effectiveness of your campaign accurately it's important to run manual Google searches for your target keywords.`
        },
        {
            question: `Will these keywords auto update over course of time?`,
            answer: `This is a system generated report and the keywords listed are a snapshot of where the results were when the report was generated. The report auto-updates for the first 4 days after the Amp is published and then it stops. To get a more accurate idea of where the results are at a future date, you should take variations of the keywords used in the headlines of each module and run searches on Google.`
        },
        {
            question: `Can we choose our own keyword combos we can rank for?`,
            answer: `Yes. You just need to inform our writing team when submitting your amps, or make sure to include them in your headlines if you are writing the content yourself. We also have extensive training on this and you can email ${this.supportLink} if you need help.`
        },
        {
            question: `Why are my keyword pickups so low? - Why are my rankings lower than usual?`,
            answer: `Low keyword pick-ups (30 or less) are usually an indication that the competition is too high for combinations of the chosen targeted keywords. You can search on Google to see which keywords are ranking well and then utilize this information in your next campaigns by excluding keywords which didn't perform well. You can also contact ${this.supportLink} to have the report refreshed and pick up on any keywords that may have been missed when the report was generated. Also, you can ask us for help to get a better idea of why your keywords did not rank well.`
        },
        {
            question: `Where are the major sites that were promised?`,
            answer: `The affiliated sites of the major news corporations that we publish to can be found under "Recorded Full Page Placements" along with the rest of the distribution.`
        },
        {
            question: `Why are there only a handful of sites in the distribution?`,
            answer: `You should be seeing at least 300 sites. Any less than this could mean that the amp is not fully published yet. Please check the status of the Amp via your Amps page and if it is showing as "Published", email ${this.supportLink}. If not published, wait until you receive an email from us confirming it is or until you see the status update.`
        },
        {
            question: `No distribution picked up in my country (outside the US)`,
            answer: `We include publications from all over the world but not every country is included. This will not impact your results in any way. Stick to our training and publish consistently to make the most of your campaigns.`
        },
        {
            question: `Why can I not publish all the modules?`,
            answer: `If you are unable to publish the full campaign, please reach out to ${this.supportLink} for help.`
        },
        {
            question: `When can I expect results from my Amp?`,
            answer: `Once you click to "Publish" your Amp, it gets sent through a system which checks your Amp for certain criteria to ensure it can get approved by the media websites. From the time when you click Publish until the Amp gets published it takes anywhere between 48-72 hours, which is also when you can expect the results to show up on Google.`
        },
        {
            question: `Can we edit the Amp after it's published and distributed?`,
            answer: `Unfortunately, Amps are unable to be edited once they are live on the internet. Please make sure to check everything carefully before you click "Publish".`
        }
    ];

    intersecting: Record<string, SectionIntersection> = {};

    onIntersect(entries: IntersectionObserverEntry[]) {
        //  https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        if (!this.intersecting[entries[0].target.id]) {
            this.intersecting[entries[0].target.id] = {
                element: document.getElementById(entries[0].target.id)
            } as SectionIntersection;
        }

        this.intersecting[entries[0].target.id].intersecting =
            entries[0].isIntersecting;

        this.intersecting[entries[0].target.id].ratio =
            entries[0].intersectionRatio;

        const mostVisible = Object.keys(this.intersecting)
            .filter(section => this.intersecting[section].intersecting)
            .sort((a, b) => {
                const order =
                    this.intersecting[b].ratio - this.intersecting[a].ratio;

                if (order === 0) {
                    // if both visible - pick the lower one
                    return (
                        (this.intersecting[b].element?.offsetTop || 0) -
                        (this.intersecting[a].element?.offsetTop || 0)
                    );
                }

                return order;
            })[0];

        if (mostVisible) {
            this.$emit('intersect', mostVisible);
        }
    }

    mounted() {
        this.$emit('actions', {
            downloadPdf: () => this.$refs.downloader?.downloadPdf(),
            downloadCsv: () => this.$refs.downloader?.downloadCsv()
        });
    }
}
</script>

<style lang="scss" scoped>
.blue-header {
    background-color: $dark-blue;
    color: $white;
    box-shadow: 0px 0px 20px -5px #898fd6;
    border-radius: 20px 20px 0px 0px;
    padding-bottom: 130px !important;

    + .content {
        margin-top: -120px;
    }
}

.content {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 10px 50px -40px #666666;
}

[role='menu'] {
    background-color: $white;
    box-shadow: 0px 5px 10px -5px $light-black;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    opacity: 1;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .content {
        left: 10px;
        width: calc(100% - 20px);
    }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
    .content {
        left: 25px;
        width: calc(100% - 50px);
    }
}
</style>

<style lang="scss">
.report-container {
    position: relative;
}

.report-status-chip {
    position: absolute;
    top: -16px;
    right: -20px;
    font-size: 110% !important;
    border-radius: 2px !important;
    box-shadow: 1px 3px 10px -5px #898fd6;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .report-status-chip {
        right: -5px;
        font-size: 100% !important;
    }
}

.break-words {
    word-break: break-word;
}
</style>
